<template>
	<div class="full-height pa-20">
		<h6>{{ program.name }}</h6>
		<div
			class="pa-10 bg-white width-90"
		>
			<table class="table   td-left">
				<col width="120px">
				<tbody>
					<tr>
						<th>카테고리</th>
						<td>
							<select
								v-model="item.faqCategoryIdx"
								class="pa-10 "
							>
								<option value="">선택하세요</option>
								<option
									v-for="(category, index) in items_category"
									:key="'category_' + index"
									:value="category.idx"
								>{{ category.name }}</option>
							</select>
						</td>
					</tr>
					<tr>
						<th>제목</th>
						<td><input v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50"/></td>
					</tr>
					<tr>
						<th>내용</th>
						<td>

							<editor
								v-if="item.content"
								height="350px"
								initialEditType="markdown"
								ref="content"
								class="text-left "
								:initialValue="item.content"
							/>
						</td>
					</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
				>목록</button>
			</div>
		</div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

export default {
	name: 'FaqDetail'
	,components: {Editor}
	,data: function(){
		return {
			program: {
				name: 'FAQ 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {

			}
			,items_category: []
		}
	}
	,computed: {

	}
	, methods: {
		save: async function(){
			this.item.content = this.$refs.content.invoke('getMarkdown')
			this.item.QCategoryIdx = this.item.faqCategoryIdx
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'faq'
					,data: this.item
				})
				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getCategory: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'faq/category/set'
				})

				if(result.success){
					this.items_category = result.data
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'faq/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
					this.item.originIdx = this.item.idx
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,isCancel: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
		}
		,clear: function(){
			this.is_modal = false
		}
		,toList: function(){
			this.$router.back()
		}
	}
	, created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.$layout.onLoad(this.program)
		this.getCategory()
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>